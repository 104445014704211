export default class TrustindexWidget {
	constructor(widget, element = null)
	{
		this.classes = [];

		if (widget) {
			this.getWidget(widget);

			if (!widget.container) {
				return;
			}

			this.waitForVisibility(() => {
				this.format();
				this.resize(true);
				this.registerEvents();
			});
		}

		if (element) {
			this.load(element);
		}

		window.tiWidgetInstances.push(this);
	}

	getWidget(widget)
	{
		widget.setAttribute('data-trustindex-widget', true);

		widget.layoutId = widget.getAttribute('data-layout-id');
		widget.layoutCategory = (widget.getAttribute('data-layout-category') || "").split(', ');
		widget.setId = widget.getAttribute('data-set-id');
		widget.pid = widget.getAttribute('data-pid') || 'wp-widget';
		widget.cssVersion = widget.getAttribute('data-css-version') || null;
		widget.locales = {};

		if (widget.layoutId) {
			widget.layoutId = parseInt(widget.layoutId);
		}

		widget.container = widget.querySelector('.ti-widget-container');

		// translate press debug
		// - https://translatepress.com/docs/developers/exclude-certain-text-or-element-from-being-translated/
		widget.setAttribute('data-no-translation', true);

		this.widget = widget;
		this.getSubclasses();
	}

	getSubclasses()
	{
		this.widget.TrustindexWidget = this;
		this.classes = [];

		if (this.widget.querySelector('.ti-reviews-container')) {
			this.widget.TrustindexReviewWidget = new TrustindexReviewWidget(this.widget);
			this.classes.push(this.widget.TrustindexReviewWidget);

			if (this.widget.getAttribute('data-column-vertical-separate')) {
				this.widget.TrustindexMasonryWidget = new TrustindexMasonryWidget(this.widget);
				this.classes.push(this.widget.TrustindexMasonryWidget);
			}

			if (this.widget.querySelector('script.ti-ai-summary')) {
				this.widget.TrustindexAiSummaryModule = new TrustindexAiSummaryModule(this.widget);
				this.classes.push(this.widget.TrustindexAiSummaryModule);
			}

			if ('readmore' === this.widget.reviewTextMode && this.widget.querySelector('.ti-review-item .ti-read-more')) {
				this.widget.TrustindexReadMoreModule = new TrustindexReadMoreModule(this.widget);
				this.classes.push(this.widget.TrustindexReadMoreModule);
			}

			if (this.widget.getAttribute('data-pager-autoplay-timeout')) {
				this.widget.TrustindexSliderWidget = new TrustindexSliderWidget(this.widget);
				this.classes.push(this.widget.TrustindexSliderWidget);
			}

			if (this.widget.querySelector('.ti-load-more-reviews-button')) {
				this.widget.TrustindexLoadMoreModule = new TrustindexLoadMoreModule(this.widget);
				this.classes.push(this.widget.TrustindexLoadMoreModule);
			}

			if (this.widget.querySelector('.ti-widget-header')) {
				this.widget.TrustindexHeaderModule = new TrustindexHeaderModule(this.widget);
				this.classes.push(this.widget.TrustindexHeaderModule);
			}

			if (this.widget.querySelector('script.ti-lightbox-data')) {
				this.widget.TrustindexReviewImageModule = new TrustindexReviewImageModule(this.widget);
				this.widget.TrustindexLightboxModule = new TrustindexLightboxModule(this.widget);
				this.classes.push(this.widget.TrustindexReviewImageModule);
				this.classes.push(this.widget.TrustindexLightboxModule);
			}
		}

		if (-1 !== this.widget.layoutCategory.indexOf('floating') || this.widget.querySelector('.disable-widget')) {
			this.widget.TrustindexFloatingWidget = new TrustindexFloatingWidget(this.widget);
			this.classes.push(this.widget.TrustindexFloatingWidget);
		}

		if (this.widget.querySelector('a[href="#popup"], a[href="#dropdown"]')) {
			this.widget.TrustindexPopupWidget = new TrustindexPopupWidget(this.widget);
			this.classes.push(this.widget.TrustindexPopupWidget);
		}

		if (this.widget.querySelector('.ti-top-rated-title')) {
			this.widget.TrustindexTopRatedWidget = new TrustindexTopRatedWidget(this.widget);
			this.classes.push(this.widget.TrustindexTopRatedWidget);
		}
	}

	callSubclassesMethods()
	{
		let methodName = arguments[0];

		this.classes.forEach(c => {
			if (typeof c[methodName] === 'function') {
				c[methodName](...Array.from(arguments).slice(1));
			}
		});
	}

	load(element)
	{
		// parse HTML
		let dom = (new DOMParser()).parseFromString(element.contentHtml, 'text/html');
		let widget = dom.querySelector('.ti-widget');

		// add widget to DOM
		element.replaceWith(widget);

		// load widget data
		this.getWidget(widget);

		// hide widget only debug messages left for us
		widget.toggleHide();

		// error debug
		if (!widget.layoutId || !widget.container) {
			return widget.innerHTML = 'Layout id or container not found!';
		}

		// add float widgets to body
		if (-1 !== this.widget.layoutCategory.indexOf('floating') || [17, 21, 52, 53].indexOf(widget.layoutId) !== -1) { // layout-id for backward compatibility, we can delete it later
			if (!document.body) {
				return setTimeout(() => this.load(element), 40);
			}

			document.body.appendChild(widget);

			let checks = 0;
			widget.floatBodyCheckInterval = setInterval(() => {
				if (widget.parentNode !== document.body) {
					document.body.appendChild(widget);
					clearInterval(widget.floatBodyCheckInterval);
				}

				checks++;
				if (checks > 20) {
					clearInterval(widget.floatBodyCheckInterval);
				}
			}, 100);
		}

		console.log('[Trustindex Widget: '+this.widget.pid+' - '+this.widget.layoutId+'] content loaded');

		// insert other elements after widget such as <style>
		dom.body.querySelectorAll('*').forEach(element => widget.after(element));

		let cssUrl = widget.setId ? Trustindex.getCDNUrl() + 'assets/widget-presetted-css/' + widget.layoutId + '-' + widget.setId + '.css' : null;
		if (widget.cssVersion) {
			cssUrl = cssUrl.replace('widget-presetted-css/', 'widget-presetted-css/v'+ widget.cssVersion + '/');
		}

		if (element.key === 'wp-widget') {
			cssUrl = element.getAttribute('data-css-url');
		}

		Trustindex.addCSS(cssUrl, () => {
			widget.toggleShow();

			this.waitForVisibility(() => {
				this.format();
				this.resize(true);
				this.registerEvents();
			});
		});
	}

	format()
	{
		// logo additional styles
		if (typeof this.widget.TrustindexTopRatedWidget === 'undefined' || typeof this.widget.TrustindexSliderWidget !== 'undefined') {
			this.widget.querySelectorAll(`
				.ti-header-logo .ti-header-logo-img:not([data-logo-styles-added]),
				.ti-large-logo img:not([data-logo-styles-added]),
				.ti-small-logo img:not([data-logo-styles-added])
			`).forEach(img => {
				let type = 'small-logo';
				if (img.matches('.ti-header-logo .ti-header-logo-img')) {
					type = 'header-logo';
				} else if (img.matches('.ti-large-logo img')) {
					type = 'large-logo';
				}

				let platform = img.getAttribute('alt');
				let styles = Trustindex.additionalLogoStyles[type][platform] || null;

				// domain platforms (like Szallashu-cz)
				let match = /\/logo-([^-\.]+)/.exec(img.src);
				if (match) {
					styles = Trustindex.additionalLogoStyles[type][platform + '-' + match[1]] || styles;
				}

				if (styles) {
					img.setAttribute('style', styles.replace(/((?:height|width): \d+px)/g, '$1 !important'));
				}

				img.setAttribute('data-logo-styles-added', 1);
			});
		}

		// sprite image
		this.widget.querySelectorAll('.ti-profile-img-sprite').forEach(spriteImage => this.widget.TrustindexWidget.loadSpriteImage(spriteImage));

		// sprite image (company-image)
		this.widget.querySelectorAll('.ti-company-sprite-profile-image').forEach(spriteImage => this.loadSpriteImage(spriteImage));

		this.callSubclassesMethods('format');
	}

	resize(forceResize = false)
	{
		if (typeof this.widget.TrustindexReviewWidget === 'undefined') {
			return;
		}

		// widget overflow with width: 100% workaround
		this.widget.style.width = ''; // set back any changes to width on DOM
		let currentWidth = this.widget.offsetWidth; // get current width of widget
		this.widget.toggleHide(); // hide widget so we can compute parent's width

		let width = window.getComputedStyle(this.widget, null).getPropertyValue('width');
		let parent = this.widget;
		let parentWidth = 0;

		// calculate parent's inner width (widthout padding & margin)
		do {
			parent = parent.parentNode;

			if (!parent || 'HTML' === parent.nodeName) {
				break;
			}

			if (parent.clientWidth) {
				let computedStyle = window.getComputedStyle(parent, null);
				parentWidth = parent.clientWidth - parseFloat(computedStyle.paddingLeft) - parseFloat(computedStyle.paddingRight);
			}
		} while(parentWidth < 100);

		if ('100%' === width && currentWidth > parentWidth && parentWidth) {
			this.widget.style.width = parentWidth + 'px';

			this.widget.toggleShow();

			if (parentWidth < parent.offsetWidth) {
				this.widget.style.width = parent.offsetWidth + 'px';
			}
		} else {
			this.widget.toggleShow();
		}

		if (this.widget.originalColCount <= 1) {
			// call resize when needed
			this.classes.forEach(c => {
				if (typeof c.resize === 'function' && (c.callOneOriginalColumnResize || false)) {
					c.resize();
				}
			});

			return;
		}

		this.callSubclassesMethods('resize', forceResize);
		this.resizeAfter();
	}

	resizeAfter()
	{
		this.callSubclassesMethods('resizeAfter');
	}

	destroy()
	{
		this.callSubclassesMethods('destroy');

		if (this.widget.floatBodyCheckInterval) {
			clearInterval(this.widget.floatBodyCheckInterval);
		}

		delete this.widget.layoutId;
		delete this.widget.container;
		delete this.widget;
	}

	registerEvents()
	{
		this.widget.addEventListener('click', event => {
			// write button dropdown click
			if (event.target.matches('.ti-header-write-btn-container .ti-header-write-btn[href=""]')) {
				event.preventDefault();

				let dropdown = event.target.closest('.ti-header-write-btn-container').querySelector('.ti-write-btn-dropdown');
				if (!dropdown.classList.contains('ti-active')) {
					dropdown.classList.add('ti-active');

					// remove active class with every other click (outside or any dropdown item click)
					setTimeout(() => {
						document.addEventListener('click', () => dropdown.classList.remove('ti-active'), { once : true });
					}, 1);
				}
			}

			this.callSubclassesMethods('click', event);
		});
	}

	documentClick()
	{
		this.callSubclassesMethods('documentClick', event);
	}

	waitForVisibility(callback, timeout = 0)
	{
		if (this.widget.container.offsetWidth > 0) {
			if (timeout) {
				return setTimeout(callback, timeout);
			}

			return callback();
		}

		new ResizeObserver(function(entries) {
			if (entries[0].target.offsetWidth > 0) {
				callback();
				this.disconnect();
			}
		}).observe(this.widget.container);
	}

	loadSpriteImage(element)
	{
		let spriteUrl = Trustindex.getWidgetUrl(this.widget.pid) + 'sprite.jpg';
		let index = 0;
		let height = 0;
		let topMargin = 0;

		// get review's data
		if (!element.classList.contains('ti-company-sprite-profile-image')) {
			let parent = element.closest('.ti-review-item');
			let style = getComputedStyle(element);

			if (!parent) {
				parent = element.closest('.ti-profile-img');
			}

			// style not parsed - call fv again after 100 ms
			if ('auto' === style.height) {
				return setTimeout(() => this.loadSpriteImage(element), 100);
			}

			index = parent.getAttribute('data-index') ? parseInt(parent.getAttribute('data-index')) : [].indexOf.call(parent.parentNode.children, parent) - parent.parentNode.querySelectorAll('.ti-ai-summary-item').length;
			height = parseInt(style.height || '0');

			if (!height || isNaN(height)) {
				height = 40;
			}

			// add top margin because the first image is the company's profile image in the sprite
			let companyImageElement = this.widget.querySelector('.ti-company-sprite-profile-image')
			if (companyImageElement) {
				style = getComputedStyle(companyImageElement);
				topMargin = parseInt(style.height || '0');

				if (!topMargin || isNaN(topMargin)) {
					topMargin = 65;
				}
			}
		}

		let changeBackground = function () {
			element.style.background = 'url("'+ spriteUrl +'") 0 '+ (index * height * -1 - topMargin) + 'px';
		};

		if (element.getAttribute('data-webp')) {
			Trustindex.isWebpSupported(isSupported => {
				if (isSupported) {
					spriteUrl = spriteUrl.replace('.jpg', '.webp');
				}

				changeBackground();
			});
		} else {
			changeBackground();
		}
	}
};